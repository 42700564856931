import "../styles/index.scss";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Dropdown } from "tailwindcss-stimulus-components";
import Turbolinks from "turbolinks"

console.info("Bridgetown is loaded!");
const application = Application.start();
const context = require.context("./controllers", true, /.js$/);
application.load(definitionsFromContext(context));

application.register("dropdown", Dropdown);
Turbolinks.start()
